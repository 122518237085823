import { OidcService } from '@fnz/oidc-authentication';
import cloneDeep from 'lodash-es/cloneDeep';

import { getAuthorization } from './authorization';
import { AuthService } from './authorization/auth.service';
import { TokenExchangeManager } from './authorization/token-exchange';
import { addUpdateUserEventContext } from './authorization/utils/add-update-user-event-context';
import { BreadcrumbsManager } from './breadcrumbs-manager';
import { ScopedBreadcrumbsManager } from './breadcrumbs-manager/scoped-breadcrumb-manager';
import { DockConfiguration, MicrofrontendConfiguration } from './configuration';
import {
  getUtilityModuleDefinitions,
  processConfiguration,
} from './configuration/process-configuration';
import { AuthConfigurationSchema } from './configuration/schemas/auth';
import { DockContext } from './context';
import { ApplicationLinkConfiguration, LinksManager } from './links-manager';
import { ScopedLinksManager } from './links-manager/scoped-links-manager';
import { NavigatorManager } from './navigation-manager';
import { ThemeManager } from './theme-manager';
import { ScopedThemeManager } from './theme-manager/scoped-theme-manager';
import {
  InternalDockSDK,
  JSONArray,
  JSONObject,
  ScopedDockSDK,
  SharedSDK,
  SharedSDKElements,
  SingleSpaEntry,
} from './types';
import { getNavigateTo, NavigateTo } from './utility/navigate-to';

let initializeFunction: (val?: unknown) => void;
const initialize = new Promise((resolve, reject) => {
  initializeFunction = resolve;
});

interface BootstrapConfig {
  url?: string;
  configuration?: JSONObject;
}

let linksManager: LinksManager;
const dockContext = new DockContext();
const breadcrumbsManager = new BreadcrumbsManager(dockContext);
const themeManager = new ThemeManager();
let tokenExchangeManager: TokenExchangeManager;
let userManager: AuthService;
const navigatorManager = new NavigatorManager();
let navigateTo: NavigateTo;

const fetchDockConfiguration = async (
  config: BootstrapConfig,
): Promise<JSONObject> => {
  let configurationJson: JSONObject;
  if (config.url) {
    configurationJson = await fetch(config.url).then(
      (response) => response.json() as Promise<JSONObject>,
    );
  }
  if (config.configuration) {
    configurationJson = config.configuration;
  }
  return configurationJson!;
};

let dockConfiguration: DockConfiguration;

export const getDockConfigurationData = (): DockConfiguration =>
  cloneDeep(dockConfiguration);

export const getLinksManager = () => linksManager;
export const getContext = () => dockContext;
export const getBreadcrumbManager = () => breadcrumbsManager;
export const getThemeManager = () => themeManager;
export const getNavigatorManager = () => navigatorManager;

const DOCK_INITIAL_URL = 'DOCK:OneX-initial-url';
const setInitialUrl = () => {
  const url = localStorage.getItem(DOCK_INITIAL_URL);
  if (!url) {
    localStorage.setItem(DOCK_INITIAL_URL, window.location.href);
  }
};

export const bootstrap = async (
  config: BootstrapConfig = { url: '/dock-config.json' },
  extend: JSONObject = {},
): Promise<DockConfiguration> => {
  setInitialUrl();
  // fetch dock configuration
  const unprocessedDockConfiguration = await fetchDockConfiguration(config);
  // get auth
  const { authConfiguration } = unprocessedDockConfiguration;

  userManager = await getAuthorization(
    AuthConfigurationSchema.parse(authConfiguration),
  );
  addUpdateUserEventContext(userManager, dockContext);
  const utilityModules = getUtilityModuleDefinitions(
    (unprocessedDockConfiguration.microfrontends as JSONArray) || [],
  );
  addMicrofrontendsToImportMaps(utilityModules);
  // process dock configuration by fetching microfrontends with accessToken
  dockConfiguration = await processConfiguration(
    { ...unprocessedDockConfiguration, ...extend },
    userManager.accessToken,
    dockContext,
  );
  addMicrofrontendsToImportMaps(dockConfiguration.microfrontends);
  linksManager = new LinksManager(dockConfiguration);
  if (
    dockConfiguration.authConfiguration.type === 'oidc' &&
    dockConfiguration.authConfiguration.tokenExchange
  ) {
    tokenExchangeManager = new TokenExchangeManager(
      (userManager as OidcService).userManager,
      dockConfiguration.authConfiguration.tokenExchange,
    );
  }

  prepareBreadcrumbs(dockConfiguration.microfrontends);
  if (dockConfiguration.navigatorConfiguration?.links) {
    const defaultPinnedApps = dockConfiguration.navigatorConfiguration?.links;
    navigatorManager.setNavigatorLinks(defaultPinnedApps);
  }
  initializeFunction();
  navigateTo = getNavigateTo(dockConfiguration.legacySSR);
  window.dispatchEvent(new Event('Dock:authLiftToMemory'));
  localStorage.removeItem(DOCK_INITIAL_URL);
  return cloneDeep(dockConfiguration);
};

const addMicrofrontendsToImportMaps = (
  microfrontends: DockConfiguration['microfrontends'],
) => {
  const importMaps: Record<string, string> = microfrontends.reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue.id]: currentValue.url,
    }),
    {},
  );
  return System.addImportMap({ imports: importMaps });
};

const prepareBreadcrumbs = (
  microfrontends: DockConfiguration['microfrontends'],
) => {
  const breadcrumbManager = getBreadcrumbManager();
  microfrontends.forEach((mfe) => {
    if (mfe.type === 'application') {
      const linkConfiguration: ApplicationLinkConfiguration = {
        appId: mfe.id,
        basePath: mfe.path,
      };
      breadcrumbManager.registerBreadcrumb(mfe.id, linkConfiguration);
    }
  });
};

export const getSingleSpaMicrofrontend = async (
  id: string,
): Promise<SingleSpaEntry> => {
  await initialize;

  try {
    return await System.import(id);
  } catch (error) {
    console.error(`Failed to load microfrontend ${id}:`, error);
    throw error;
  }
};

export const getUtilityModule = async <T>(
  id: string,
): Promise<{ default: T } & T> => {
  await initialize;
  return System.import(id);
};

export const getUserManager = async () => {
  await initialize;
  return userManager;
};

export const hasMicrofrontend = (
  id: string,
  includeConfig?: boolean,
): boolean | DockConfiguration['microfrontends'][number] => {
  const mfe = dockConfiguration?.microfrontends.find((mfe) => mfe.id === id);
  if (!!mfe && includeConfig) {
    return mfe;
  }
  return !!mfe;
};

export const getSDK = async (
  mfeConfig: MicrofrontendConfiguration,
  includeExchangedToken: boolean = true,
): Promise<ScopedDockSDK> => {
  const isExternal =
    typeof mfeConfig.customProps?.['external'] === 'boolean'
      ? mfeConfig.customProps?.['external']
      : false;
  const getToken = (() => {
    if (isExternal && tokenExchangeManager && includeExchangedToken) {
      const config = mfeConfig.customProps?.tokenExchange as
        | Record<string, string>
        | undefined;
      const exchange = tokenExchangeManager.getTokenExchange(
        mfeConfig.id,
        config,
      );
      exchange?.updateToken();
      return () => exchange?.getToken() || '';
    }
    return () => userManager.accessToken;
  })();

  const sharedSDKElements: SharedSDKElements = {
    getToken,
    themeManager: () => new ScopedThemeManager(themeManager, isExternal),
    linksManager: () => new ScopedLinksManager(linksManager, mfeConfig.id),
    context: () => dockContext,
    breadcrumbManager: () =>
      new ScopedBreadcrumbsManager(breadcrumbsManager, isExternal),
    navigateTo: navigateTo,
    getUtilityModule: getUtilityModule,
  };

  const sharedSDK: SharedSDK = { type: 'shared', ...sharedSDKElements };

  if (isExternal) {
    return sharedSDK;
  }

  const internalSDK: InternalDockSDK = {
    type: 'internal',
    ...sharedSDKElements,
    userInformation: () => userManager.userInfo,
    userManager: () => userManager,
    navigatorManager: () => navigatorManager,
    getMicrofrontend: getSingleSpaMicrofrontend,
    hasMicrofrontend: hasMicrofrontend,
  };

  return internalSDK;
};

export { BREADCRUMBS_UPDATE_EVENT } from './breadcrumbs-manager';
export { DOCK_USER_UPDATE_EVENT } from './authorization/utils/add-update-user-event-context';

export type {
  DockConfiguration,
  MicrofrontendConfiguration,
  NavigatorConfiguration,
  FooterConfiguration,
  MountableMicrofrontendConfiguration,
} from './configuration';
export type { BreadcrumbNode } from './breadcrumbs-manager';
export type { AuthService } from './authorization/auth.service';
export type * from './context/types';

export * from './types';
